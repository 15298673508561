
import { IonPage, IonContent, IonIcon } from "@ionic/vue";
import { defineComponent, ref, reactive, computed } from "vue";
import { logoFacebook, logoGoogle, logoApple } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import httpApp from "@/libs/http";
import settingsApp from "@/libs/settings";
import { useGlobalFunctions } from "@/libs/globalFunctions";

import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

export default defineComponent({
  name: "Dashboard",
  setup() {
    const { t, te } = useI18n({ useScope: "global" });
    const router = useRouter();
    const { createLoading, openToast } = useGlobalFunctions();
    const storeX = useStore();

    const newsletterSubs = ref(false);
    const gettingNewsletter = ref(true);
    const defaultAddresses = ref([]);
    const gettingAddresses = ref(true);

    const getNewsletterSubs = () => {
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("isSubscribedNewsletter"))
        .then((resp: any) => {
          newsletterSubs.value = resp.success;
          gettingNewsletter.value = false;
        })
        .catch(() => {
          gettingNewsletter.value = false;
        });
    };

    const newsletterSubscribe = async (event) => {
      event.preventDefault();
      if (!storeX.state.customerData.account_data.email) {
        openToast(t("account.noEmailAssosiatedAccount"), "danger");
        return;
      }
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("subscribeNewsletter"), {
          email: storeX.state.customerData.account_data.email,
        })
        .then((resp: any) => {
          if (resp.success) {
            newsletterSubs.value = true;
          } else {
            if (te("apiMsgs." + resp.error.code)) {
              openToast(t("apiMsgs." + resp.error.code), "danger");
            } else {
              openToast(resp.error.message, "danger");
            }
          }
          loading.dismiss();
        })
        .catch(() => {
          openToast(t("errorMsgs.errorOccurred"), "danger");
          loading.dismiss();
        });
    };

    const newsletterUnSubscribe = async (event) => {
      event.preventDefault();
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("unsubscribeNewsletter"))
        .then((resp: any) => {
          if (resp.success) {
            newsletterSubs.value = false;
          } else {
            if (te("apiMsgs." + resp.error.code)) {
              openToast(t("apiMsgs." + resp.error.code), "danger");
            } else {
              openToast(resp.error.message, "danger");
            }
          }
          loading.dismiss();
        })
        .catch(() => {
          openToast(t("errorMsgs.errorOccurred"), "danger");
          loading.dismiss();
        });
    };

    const getDefaultAddresses = () => {
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("getDefaultAddresses"))
        .then((resp: any) => {
          if (resp.success) {
            defaultAddresses.value = resp.data;
          } else {
            defaultAddresses.value = [];
          }
          gettingAddresses.value = false;
        })
        .catch(() => {
          gettingAddresses.value = false;
        });
    };

    const icons = reactive({
      logoFacebook,
      logoGoogle,
      logoApple,
    });

    const facebookProfile = ref();
    const facebookLoginUrl = ref(null);
    const googleProfile = ref();
    const googleLoginUrl = ref(null);
    const appleProfile = ref();
    const appleLoginUrl = ref(null);
    const domainLangUrl = window.location.origin + "/" + storeX.state.storeCode;

    const socialEnabled = computed(() => {
      return facebookProfile.value ||
        facebookLoginUrl.value ||
        googleProfile.value ||
        googleLoginUrl.value ||
        appleProfile.value ||
        appleLoginUrl.value
        ? true
        : false;
    });

    const getFacebookAuthUrl = () => {
      const callbackUrl = domainLangUrl + "/account/socialconnect/facebook";
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getFacebookAuthUrl"), {
          callback_url: callbackUrl,
        })
        .then((resp: any) => {
          if (resp.success) {
            facebookLoginUrl.value = resp.url;
          }
        })
        .catch(() => {
          facebookLoginUrl.value = null;
        });
    };
    const getFacebookProfile = () => {
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("getFacebookProfile"))
        .then((resp: any) => {
          if (resp.success) {
            facebookProfile.value = resp.profile;
          } else {
            facebookProfile.value = null;
            if (resp.error.code === "epowercrm_account_130") {
              getFacebookAuthUrl();
            }
          }
        })
        .catch(() => {
          facebookProfile.value = null;
        });
    };

    const getGoogleAuthUrl = () => {
      const callbackUrl = domainLangUrl + "/account/socialconnect/google";
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getGoogleAuthUrl"), {
          callback_url: callbackUrl,
        })
        .then((resp: any) => {
          if (resp.success) {
            googleLoginUrl.value = resp.url;
          }
        })
        .catch(() => {
          googleLoginUrl.value = null;
        });
    };
    const getGoogleProfile = () => {
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("getGoogleProfile"))
        .then((resp: any) => {
          if (resp.success) {
            googleProfile.value = resp.profile;
          } else {
            googleProfile.value = null;
            if (resp.error.code === "epowercrm_account_130") {
              getGoogleAuthUrl();
            }
          }
        })
        .catch(() => {
          facebookProfile.value = null;
        });
    };

    const getAppleAuthUrl = () => {
      const callbackUrl = domainLangUrl + "/account/socialconnect/apple";
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getAppleAuthUrl"), {
          callback_url: callbackUrl,
        })
        .then((resp: any) => {
          if (resp.success) {
            appleLoginUrl.value = resp.url;
          }
        })
        .catch(() => {
          appleLoginUrl.value = null;
        });
    };
    const getAppleProfile = () => {
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("getAppleProfile"))
        .then((resp: any) => {
          if (resp.success) {
            appleProfile.value = resp.profile;
          } else {
            appleProfile.value = null;
            if (resp.error.code === "epowercrm_account_130") {
              getAppleAuthUrl();
            }
          }
        })
        .catch(() => {
          facebookProfile.value = null;
        });
    };

    const nav = (path) => {
      router.push(path);
    };

    function ionViewWillEnter() {
      getNewsletterSubs();
      getDefaultAddresses();
      getFacebookProfile();
      getGoogleProfile();
      getAppleProfile();
    }

    return {
      t,
      storeX,
      ionViewWillEnter,
      gettingNewsletter,
      newsletterSubs,
      newsletterSubscribe,
      newsletterUnSubscribe,
      nav,
      defaultAddresses,
      gettingAddresses,
      icons,
      facebookProfile,
      facebookLoginUrl,
      googleProfile,
      googleLoginUrl,
      appleProfile,
      appleLoginUrl,
      socialEnabled,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonIcon,
    AccountNavComponent,
    MainHeader,
    MainFooter,
  },
});
